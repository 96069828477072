import { reactive,createApp, ref,onMounted  } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './deepseek.vue'

frappe.deepseek_vue = class DeepSeek {
	constructor(el) {
        // 注意这里参数名从 el 改为 selector，以更清晰地表明它是一个选择器字符串
        // this.el = $(selector).get(0); // 使用 .get(0) 获取原生 DOM 元素
		this.el = el
        if (this.el) { // 检查是否成功获取到 DOM 元素
            this.init();
        } else {
            console.error('无法找到指定的 DOM 元素');
        }
    }

    init() {
        const app = createApp(App);
        app.config.devtools = true; // 注意：这里设置了两次 devtools，可以合并或移除一次
        app.use(ElementPlus, {
            locale: zhCn,
        });
        for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
            app.component(key, component);
        }
        app.mount(this.el);
    }

    // 如果需要提供一个方法来销毁实例，可以添加以下方法
    destroy() {
        if (this.el.__vue_app__) {
            this.el.__vue_app__.$unmount(); // Vue 3.2+ 提供的卸载方法
            delete this.el.__vue_app__;
            delete this.el.__vue_instance__;
        }
    }
}