<template>
	<el-button type="primary" size="" plain @click="dialogVisible = true">
		<svg class="icon  icon-lg" style="" aria-hidden="true">
			<use class="" href="#icon-deepseek"></use>
		</svg>

		<el-dialog style="width:90%;" draggable v-model="dialogVisible">
			<template #header="{ titleId, titleClass }">
				<div class="flex justify-content-between align-items-center">
					<div class="flex align-items-center">
						<img :id="titleId" :class="titleClass" style="max-height: 32px; margin-right: 6px;"
							:src="ds_settings.icon">
						<el-select v-model="select_model" placeholder="Select" size="large" style="width: 240px">
							<el-option v-for="item in models" :key="item.value" :label="item.label"
								:value="item.value" />
						</el-select>
					</div>

					<el-button-group>
						<el-button type="primary" plain>{{ tokens.prompt_tokens }}</el-button>
						<el-button type="primary" plain>{{ tokens.completion_tokens }}</el-button>
						<el-button type="primary">{{ tokens.total_tokens }}</el-button>
						<el-button type="danger">
							<el-icon size="18" @click="clear_messages">
								<Delete />
							</el-icon>
						</el-button>
					</el-button-group>
				</div>
			</template>

			<div class="flex flex-column" style="">
				<el-card
					body-style="width: 100%;text-align: left;min-height:60vh;max-height:60vh;overflow-y: auto;margin-top: 10px;margin-bottom: 10px;"
					shadow="always" ref="contentContainer">
					<div v-html="content"></div>
				</el-card>

				<div class="flex w-100 align-items-center my-2">
					<el-input v-model="textarea" style="width: 100%;flex-grow: 1;margin-right:10px;" type="textarea"
						:autosize="{ minRows: 2, maxRows: 4 }" placeholder="有什么我能帮助您的吗？(Ctl + Enter可以发送查询)"
						@keyup.enter="get_completions" />
					<el-button circle type="primary" @click="btn_submit">
						<el-icon loading="loading" size="24">
							<Promotion />
						</el-icon>
					</el-button>
				</div>
			</div>

		</el-dialog>
	</el-button>

</template>
<script setup>
import { ref, reactive, onMounted, nextTick } from 'vue';
import { Delete, Edit, Search, Share, Upload, Refresh, Promotion } from '@element-plus/icons-vue'
const contentContainer = ref(null);
const dialogVisible = ref(false)
const ds_settings = ref()
const select_model = ref('')
const icon = ref(`<svg class="icon  icon-lg" style="" aria-hidden="true">
			<use class="" href="#icon-deepseek"></use>
		</svg>`)
const models = ref([])
const textarea = ref('')
const content = ref('')
const response = ref([])
const loading = ref(false)
const submit_query = ref(false)
const tokens = reactive({
	completion_tokens: 0,
	prompt_tokens: 0,
	total_tokens: 0
})
const messages = ref([])

const btn_submit = () => {
	submit_query.value = true
	get_completions()
}
const get_completions = (event) => {
	if (!submit_query.value) {
		return
	} else if (!textarea.value || textarea.value.trim() === '') {
		frappe.throw(__("请输入查询内容")); return
	}

	if (loading.value) {
		frappe.throw(__("正在查询中，请稍后"))
		return
	}
	loading.value = true
	content.value += `
		<div class="flex justify-content-end">
			<div class="bg-light rounded p-2" style="text-align: right;">
				${frappe.markdown(textarea.value)}
			</div>
		</div>`
	messages.value.push({
		role: "user",
		content: textarea.value,
		name: frappe.session.user
	})
	let args = {
		text: textarea.value,
		model: select_model.value,
		messages: messages.value
	}
	frappe.dom.freeze()
	frappe.call({
		method: "localization.deep_seek.api.get_completions_openai",
		args: args,
		callback: (res) => {
			loading.value = false
			if (!res.message) {
				frappe.dom.unfreeze()
				frappe.throw(__("查询失败"))
				return
			}
			textarea.value = ''
			submit_query.value = false;
			frappe.dom.unfreeze()
			let data = res.message
			// console.log(data)

			if (data.doc.content) {
				messages.value.push({
					role: "assistant",
					content: data.doc.content,
					name: frappe.session.user
				})

				let doc = data.doc;
				tokens.completion_tokens = doc.completion_tokens
				tokens.prompt_tokens = doc.prompt_tokens
				tokens.total_tokens = doc.total_tokens
				response.value.push(frappe.markdown(data.doc.content))
				let html = ''
				if (data.doc.reasoning_content) {
					html += `<div class="text-secondary p-4 my-4 rounded shadow bg-sky-500/50" ><div class="font-weight-bold my-1">推理过程:</div>${frappe.markdown(data.doc.reasoning_content)}</div>`
				}
				html += `<div class="">${frappe.markdown(data.doc.content)}</div>`
				content.value += `
					<div class="flex">
						<div style="max-width:24px;">${icon.value}</div>
						<div class="">
							<div style="font-weight: bold;padding: 6px;"">${select_model.value}</div>
							<div style="white-space: break-spaces;" class="flex-grow-1">
								${html}
							</div>
						</div>
					</div>
					`

				nextTick(() => {
					const container = contentContainer.value;
					if (container) {
						container.scrollTop = container.scrollHeight;
					}
				});
			}
		}
	})
}
const clear_messages = () => {
	messages.value = []
	content.value = `<div style="text-align: center;">欢迎 ${frappe.user.full_name()}!</div>`
	tokens.completion_tokens = 0
	tokens.prompt_tokens = 0
	tokens.total_tokens = 0
}
onMounted(() => {
	ds_settings.value = window.ds_settings;
	if (window.ds_settings) {
		window.ds_settings.model.forEach(model => {
			models.value.push({
				value: model.model,
				label: model.model,
			},)
		})
		select_model.value = models.value[0].value;
	}

	document.addEventListener('keydown', function (event) {
		if (event.key === 'Enter') {
			if (event.ctrlKey) {
				submit_query.value = true;
			} else {
				submit_query.value = false;
			}
		}
	});
	content.value = `<div style="text-align: center;">欢迎 ${frappe.user.full_name()}!</div>`
});
</script>
<style scoped></style>