import { reactive,createApp, ref,onMounted  } from 'vue';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './deepseek_stream.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

frappe.deepseek_stream = class DeepSeekStream {
	constructor(el) {
		this.el = el;
		this.el.innerHTML = `<div id="deepseek_stream"></div>`
		this.init()
	}

    init() {
		const app = createApp(App);
		app.config.devtools = true
		app.use(ElementPlus, {
			locale: zhCn,
		})
		for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
			app.component(key, component)
		}
		app.mount('#deepseek_stream');
	}
}