erpnext.queries.get_filtered_dimensions = function(doc, child_fields, dimension, 
company) {
    let account = ''; if (dimension && dimension.toLowerCase() === 'project'){ 
        return {
			query: "localization.localization.utils.api.get_permit_project_list", 
        }
    } else{
        child_fields.forEach((field) => { if (!account) { account = doc[field];
            }
        });
        return { query: "erpnext.controllers.queries.get_filtered_dimensions", 
            filters: {
                'dimension': dimension, 'account': account, 'company': company
            }
        };
    }
}

frappe.ui.form.on("Opportunity", {
	setup: function(frm) {
		frm.set_query("opportunity_manager", function() {
			return {
				query: "erpnext.controllers.queries.employee_query"
			};
		});
	}
})

frappe.ui.form.on("Employee Advance", {
	setup: function(frm) {
		frm.set_query("payee", function() {
			return {
				query: "erpnext.controllers.queries.employee_query"
			};
		});
	},
})

frappe.ui.form.on('Material Request',{
	onload_post_render : function(frm) {
		var bt = __('Stop')
		if (!frappe.user.has_role('GM')) {
			frm.page.remove_inner_button(bt)
		}
	},

	refresh: function(frm) {
		if(!frm.doc.schedule_date) {
			frm.doc.schedule_date = frappe.datetime.add_days(frappe.datetime.get_today(), 7);
		}
	},
})

frappe.ui.form.on("Journal Entry Template", {
	onload: function(frm) {
		frm.set_query(
			"transaction_type", function(doc, cdt, cdn) {
				return {
					filters: {
						"disable": 0
					}
				}
			}
		);
	}
})

// 解决会计总账报表选择项目的时候自带公司过滤条件的问题
function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
	var startTimeInMs = Date.now();
	(function loopSearch() {
		if (document.querySelector(selector) != null) {
		callback();
		return;
		}
		else {
		setTimeout(function () {
			if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
			return;
			loopSearch();
		}, checkFrequencyInMs);
		}
	})();
}

$(document).on("page-change", function () {
    if (window.location.pathname == '/app/query-report/General%20Ledger'){
		waitForElementToDisplay('.multiselect-list[data-fieldname="project"]',
		function (){
			if (frappe.query_reports['General Ledger']){
				let filter = frappe.query_reports['General Ledger'].filters.filter((r,i) =>{return r.fieldname==='project'});
				if (filter){
					filter[0].get_data = function(txt) {
						return frappe.db.get_link_options('Project', txt)
					}   
				} 
			}}, 
		1000, 59000); 
	}
});

// 列表界面增加总计
frappe.views.ListView = class ListView extends frappe.views.ListView {
	render() {
		this.render_list();
		this.set_rows_as_checked();
		this.on_row_checked();
		this.render_total();
		this.render_count();
	}
  
	render_total() {
		if (this.columns.filter((col) => {return frappe.model.is_numeric_field(col.df)}).length){
			const $total = this.get_total_html();
			this.$result.find('.list-row-total').remove();
			this.$result.append($total);
		}
	}

	get_total_html() {
		if (!this.columns) {
			return;
		}

		const $columns = this.columns
		.map((col) => {
			let classes = [
			"list-row-col ellipsis",
			col.type == "Subject" ? "list-subject level" : "hidden-xs",
			col.type == "Tag" ? "tag-col hide" : "",
			frappe.model.is_numeric_field(col.df) ? "text-right" : "",
			].join(" ");

			let html = "";
			if (col.type === "Subject") {
			html = `
			<input class="level-item list-check-all" type="checkbox"
				title="${__("Select All")}">
				<span class="level-item"><b>${__('Total')}</b></span>`;
			}else if (col.type === "Field" && col.df && ['Int', 'Float', 'Currency'].includes(col.df.fieldtype)) {
			const value = this.data.reduce((sum, next) => sum + (next[col.df.fieldname] || 0), 0);
			html = `<span>${frappe.format(value, col.df)}</span>`;
			}

			return `
			<div class="${classes}">
			${html}
			</div>
		`;
		})
		.join("");

		return this.get_total_html_skeleton($columns, '<span class="list-count"></span>');
	}
	get_total_html_skeleton(left = "", right = "") {
		return `
		<footer class="level list-row-head list-row-total text-muted">
			<div class="level-left list-header-subject">
			${left}
			</div>
			<div class="level-right" style="max-width:110px;white-space: nowrap;">
			${right}
			</div>
		</footer>
		`;
	}

	get_header_html() {
		if (!this.columns) {
			return;
		}

		const subject_field = this.columns[0].df;
		let subject_html = `
			<input class="level-item list-check-all" type="checkbox"
				title="${__("Select All")}">
			<span class="level-item" data-sort-by="${subject_field.fieldname}"
				title="${__("Click to sort by {0}", [subject_field.label])}">
				${__(subject_field.label)}
			</span>
		`;
		const $columns = this.columns
			.map((col) => {
				let classes = [
					"list-row-col ellipsis",
					col.type == "Subject" ? "list-subject level" : "hidden-xs",
					col.type == "Tag" ? "tag-col hide" : "",
					frappe.model.is_numeric_field(col.df) ? "text-right" : "",
				].join(" ");

				let html = "";
				if (col.type === "Subject") {
					html = subject_html;
				} else {
					const fieldname = col.df?.fieldname;
					const label = __(col.df?.label || col.type, null, col.df?.parent);
					const title = __("Click to sort by {0}", [label]);
					const attrs = fieldname ? `data-sort-by="${fieldname}" title="${title}"` : "";
					html = `<span ${attrs}>${label}</span>`;
				}
				let width_style = ``;
				let has_width = false
				try {
					
					if(col.df?.width) {
						has_width = true;
						// 此处可以根据宽度值，处理类似px，%等各种数据
						if (!col.df.width.includes("px")) {
							col.df.width = `${parseInt(col.df.width)}px`;
						}
						width_style = `style="min-width:${col.df.width};"`
					}
					
				} catch (e) {
					console.log("doc.df is not defined or does not have a 'width' property.",e);
				}

				return `<div class="${classes}" ${width_style}>${html} </div>
			`;
			})
			.join("");

		const right_html = `
			<span class="list-count"></span>
			<span class="level-item list-liked-by-me hidden-xs">
				<span title="${__("Liked by me")}">
					${frappe.utils.icon("es-solid-heart", "sm", "like-icon")}
				</span>
			</span>
		`;

		return this.get_header_html_skeleton($columns, right_html);
	}

	get_column_html(col, doc) {
		if (col.type === "Status" || col.df?.options == "Workflow State") {
			let show_workflow_state = col.df?.options == "Workflow State";
			return `
				<div class="list-row-col hidden-xs ellipsis" style="">
					${this.get_indicator_html(doc, show_workflow_state)}
				</div>
			`;
		}

		if (col.type === "Tag") {
			const tags_display_class = !this.tags_shown ? "hide" : "";
			let tags_html = doc._user_tags
				? this.get_tags_html(doc._user_tags, 2, true)
				: '<div class="tags-empty">-</div>';
			return `
				<div class="list-row-col tag-col ${tags_display_class} hidden-xs ellipsis">
					${tags_html}
				</div>
			`;
		}

		const df = col.df || {};
		const label = df.label;
		const fieldname = df.fieldname;
		const link_title_fieldname = this.link_field_title_fields[fieldname];
		const value = doc[fieldname] || "";
		let value_display = link_title_fieldname
			? doc[fieldname + "_" + link_title_fieldname] || value
			: value;

		let translated_doctypes = frappe.boot?.translated_doctypes || [];
		if (translated_doctypes.includes(df.options)) {
			value_display = __(value_display);
		}

		const format = () => {
			if (df.fieldtype === "Percent") {
				return `<div class="progress" style="margin: 0px;">
						<div class="progress-bar progress-bar-success" role="progressbar"
							aria-valuenow="${value}"
							aria-valuemin="0" aria-valuemax="100" style="width: ${Math.round(value)}%;">
						</div>
					</div>`;
			} else {
				return frappe.format(value, df, null, doc);
			}
		};

		const field_html = () => {
			let html;
			let _value;
			let strip_html_required =
				df.fieldtype == "Text Editor" ||
				(df.fetch_from && ["Text", "Small Text"].includes(df.fieldtype));

			if (strip_html_required) {
				_value = strip_html(value_display);
			} else {
				_value =
					typeof value_display === "string"
						? frappe.utils.escape_html(value_display)
						: value_display;
			}

			if (df.fieldtype === "Rating") {
				let out_of_ratings = df.options || 5;
				_value = _value * out_of_ratings;
			}

			if (df.fieldtype === "Image") {
				html = df.options
					? `<img src="${doc[df.options]}"
					style="max-height: 30px; max-width: 100%;">`
					: `<div class="missing-image small">
						${frappe.utils.icon("restriction")}
					</div>`;
			} else if (df.fieldtype === "Select") {
				html = `<span class="filterable indicator-pill ${frappe.utils.guess_colour(
					_value
				)} ellipsis"
					data-filter="${fieldname},=,${value}">
					<span class="ellipsis"> ${__(_value)} </span>
				</span>`;
			} else if (df.fieldtype === "Link") {
				let color_style = ``
				// let color = getRandomColor()
				// if(df.fieldname == 'project') {
				// 	color_style = ` style="color: ${color};"`
				// }
				html = `<a class="filterable ellipsis"
					data-filter="${fieldname},=,${value}" ${color_style}>
					${_value}
				</a>`;
			} else if (frappe.model.html_fieldtypes.includes(df.fieldtype)) {
				html = `<span class="ellipsis">
					${_value}
				</span>`;
			} else if (df.fieldtype === "Currency") {
				let color_style = ``
				// let color = getRandomColor()
				// color_style = ` style="color: ${color};"`;

				color_style = ` style="color: blue;"`;

				html = `<a class="filterable ellipsis"
					data-filter="${fieldname},=,${frappe.utils.escape_html(value)}" ${color_style}>
					${format()}
				</a>`;

			} else {
				html = `<a class="filterable ellipsis"
					data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
					${format()}
				</a>`;
			}

			return `<span class="ellipsis"
				title="${__(label)}: ${frappe.utils.escape_html(_value)}">
				${html}
			</span>`;
		};

		const class_map = {
			Subject: "list-subject level",
			Field: "hidden-xs",
		};
		const css_class = [
			"list-row-col ellipsis",
			class_map[col.type],
			frappe.model.is_numeric_field(df) ? "text-right" : "",
		].join(" ");

		let column_html;
		if (
			this.settings.formatters &&
			this.settings.formatters[fieldname] &&
			col.type !== "Subject"
		) {
			column_html = this.settings.formatters[fieldname](value, df, doc);
		} else {
			column_html = {
				Subject: this.get_subject_element(doc, value_display).innerHTML,
				Field: field_html(),
			}[col.type];
		}
		let width_style = ``;
		let has_width = false

		try {
			
			if(col.df.width) {
				has_width = true;
				let color_style = ``
				// if(col.df.fieldname == 'project') {
				// 	color_style = ` background-color: aliceblue;
				// 		font-weight: bold;
				// 		border-radius: 6px;`;
				// }
				// 此处可以根据宽度值，处理类似px，%等各种数据
				if (!col.df.width.includes("px")) {
					col.df.width = `${parseInt(col.df.width)}px`;
				}
				width_style = `min-width:${col.df.width};${color_style}`
			}
			
		} catch (e) {
			console.log("doc.df is not defined or does not have a 'width' property.");
		}
		// 增加单据名称列的隐藏方向为左侧
		let name_style = ``
		if(col.df.fieldname == 'name' && col.type != "Subject") {
			name_style = ` direction: rtl;`
		}
		return `
			<div class="${css_class}" style="${width_style} ${name_style}">
				${column_html}
			</div>
		`;
	}
	
	// 限制最右侧宽度为100px
	get_header_html_skeleton(left = "", right = "") {
		return `
			<header class="level list-row-head text-muted">
				<div class="level-left list-header-subject">
					${left}
				</div>
				<div class="level-left checkbox-actions">
					<div class="level list-subject">
						<input class="level-item list-check-all" type="checkbox"
							title="${__("Select All")}">
						<span class="level-item list-header-meta"></span>
					</div>
				</div>
				<div class="level-right" style="max-width:110px;white-space: nowrap;">
					${right}
				</div>
			</header>
		`;
	}

	// 限制最右侧宽度为100px
	get_list_row_html_skeleton(left = "", right = "") {
		return `
			<div class="list-row-container" tabindex="1">
				<div class="level list-row">
					<div class="level-left ellipsis">
						${left}
					</div>
					<div class="level-right text-muted ellipsis" style="max-width:110px;white-space: nowrap;">
						${right}
					</div>
				</div>
				<div class="list-row-border"></div>
			</div>
		`;
	}

}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

$(document).on('page-change', function () {
	setTimeout(function () {
		// 增加对chart的label的翻译
		$('.legend-dataset-label').each(function (index, element) {
			$(this).html(__($(this).text()))
		})
		// 表单视图增加具体的修改时间戳
		if (frappe.get_route().length >= 2 && frappe.get_route()[0] === 'Form') {
			replace_timeline_content()
			setInterval(replace_timeline_content, 60000);
		}
	},
	500)
});

function replace_timeline_content() {
	let timeline_el_list = $('.timeline-content');
	if (timeline_el_list.length > 0) {
		timeline_el_list.each(function () {
			let $el = $(this);
			let $timestamp = $el.find('.frappe-timestamp').first();
			if ($timestamp.length > 0) {
				let title = $timestamp.attr('title');
				$timestamp.html('')
				$timestamp.html(`<span>${comment_when(title)}</span> <span> · ${title}</span>`);
			}
		});
	}
}


// 定制shadow element，增加tailwind css
frappe.create_shadow_element = function (wrapper, html, css, js) {
	let random_id = "custom-block-" + frappe.utils.get_random(5).toLowerCase();

	class CustomBlock extends HTMLElement {
		constructor() {
			super();

			// html
			let div = document.createElement("div");
			div.innerHTML = frappe.dom.remove_script_and_style(html);

			// link global desk css
			let link = document.createElement("link");
			link.rel = "stylesheet";
			link.href = frappe.assets.bundled_asset("desk.bundle.css");

			// 添加自定义的css和js
			let custom_element = [
				{
					type: "style",
					rel: "stylesheet",
					href: "https://cdn.tailwindcss.com"
				},
				// gantt
				{
					type: "style",
					rel: "stylesheet",
					// href: "https://cdn.jsdelivr.net/npm/dhtmlx-gantt@8.0.10/codebase/dhtmlxgantt.min.css"
					href: "/assets/localization/css/dhtmlxgantt.min.css"
				},
				{
					type: "script",
					// src: "https://cdn.jsdelivr.net/npm/dhtmlx-gantt@8.0.10/codebase/dhtmlxgantt.min.js"
					src: "/assets/localization/js/dhtmlxgantt.min.js"
				},
				{
					type: "style",
					rel: "stylesheet",
					href: frappe.assets.bundled_asset("report.bundle.css")
				},
				{
					type: "script",
					src: frappe.assets.bundled_asset("report.bundle.js")
				},
				// element-plus
				{
					type: "style",
					rel: "stylesheet",
					href: "/assets/localization/css/element-plus.css"
				},
			]

			// css
			let style = document.createElement("style");
			style.textContent = css;

			// javascript
			let script = document.createElement("script");
			script.textContent = `
				(function() {
					let cname = ${JSON.stringify(random_id)};
					let root_element = document.querySelector(cname).shadowRoot;
					${js}
				})();
			`;

			this.attachShadow({ mode: "open" });
			this.shadowRoot?.appendChild(link);
			this.shadowRoot?.appendChild(div);
			this.shadowRoot?.appendChild(style);
			this.shadowRoot?.appendChild(script);

			let that = this;
			custom_element.forEach((element) => {

				if (element.type == "style") {
					let custom_link = document.createElement("link");
					custom_link.rel = element.rel;
					custom_link.href = element.href;
					that.shadowRoot?.appendChild(custom_link);
				} else if (element.type == "script") {
					let custom_script = document.createElement("script");
					custom_script.src = element.src;
					that.shadowRoot?.appendChild(custom_script);
				}
			})
		}
	}

	if (!customElements.get(random_id)) {
		customElements.define(random_id, CustomBlock);
	}
	wrapper.innerHTML = `<${random_id}></${random_id}>`;
};

function force_fullwidth() {
	let full_width = JSON.parse(localStorage.container_fullwidth || 'false');
// 	console.log('full_width',full_width)

	if (!full_width) {
		localStorage.container_fullwidth = !full_width;
		$(document.body).toggleClass("full-width", !full_width)
		frappe.show_alert({ message: __('已自动调整为全宽页面'), indicator: "green" });
	}
}
force_fullwidth()

$(document).on('app_ready', function() {
    if (!frappe.boot.developer_mode) return;
    document.documentElement.style.setProperty('--background-color', 'gold');
})

$(document).on('app_ready', function() {
	frappe.call({
		method:"localization.deep_seek.api.get_ds_settings",
		args:{},
		callback: (r)=>{
			if (r.message) {
				window.ds_settings = r.message;
				add_deepseek_el(r.message)
			}
		}
	})

})

const add_deepseek_el = (ds_settings) => {
	// 创建一个 div 元素作为按钮容器
	let new_el = document.createElement('div');
	new_el.innerHTML = `
		<div class="btn" id="btn-deepseek">		
			<div class="btn btn-default">
			<a href="/app/deepseek" class="btn-link"  target = '_blank'>
				<svg class="icon icon-lg" aria-hidden="true">
					<use href="#icon-deepseek"></use>
				</svg>
			</a>
		</div></div>`

	// 获取父元素
	let parent_el = document.body;

	// 将 div 元素添加到父元素中
	parent_el.appendChild(new_el);

	// new frappe.deepseek_vue(new_el)
}