<template>
	<div class="flex justify-content-between align-items-center">
		<div class="flex align-items-center">
			<svg class="icon  icon-lg" style="" aria-hidden="true">
				<use class="" href="#icon-deepseek"></use>
			</svg>
			<el-select v-model="select_model" placeholder="请选择模型..." class="mx-2" style="min-width: 160px">
				<el-option v-for="item in models" :key="item.value" :label="item.label" :value="item.value" />
			</el-select>
		</div>

		<el-button-group>
			<el-button type="default" size="small">
				<el-icon size="18" @click="clear_messages">
					<Refresh />
				</el-icon>
			</el-button>
		</el-button-group>
	</div>
	<div>
		<div id="chat-container" ref="chatContainer" class="rounded w-100 my-2 text-left bg-white p-4 border"
			style="min-height:80vh;max-height:80vh;overflow-y: auto;">
			<div v-html="content" class="my-2"></div>
			<div v-for="(item, index) in context" :key="index">
				<div class="flex justify-content-end align-items-center">
					<div class="bg-light px-2 rounded mx-2" style="text-align: right;">
						<div v-html="item.question" class="my-2"></div>
					</div>
					<div v-html="avatar" class="" />
				</div>

				<div class="flex">
					<div style="max-width:24px;">
						<div v-html="icon" class="my-2"></div>
					</div>
					<div class="">
						<div class="flex align-items-center">
							<div style="font-weight: bold;padding: 6px;">
								{{ select_model }}
							</div>
							<el-button size="small" text bg type="primary" plain>
								{{ get_duration(index) }}
							</el-button>

						</div>
						<div style="white-space: break-spaces;margin-right:1rem;" class="flex-grow-1">
							<div v-html="item.reasoning_md"
								class="my-2 bg-sky-500/50 text-secondary border rounded p-2 w-100"
								style="min-height:32px;"></div>
							<div v-html="item.content_dm" class="my-2"></div>

						</div>
						<div>

							<el-button v-if="item.usage" size="small" text bg type="primary" plain>
								<el-icon color="green">
									<SortUp />
								</el-icon>
								{{ item.usage.prompt_tokens || 0 }}
							</el-button>

							<el-button v-if="item.usage" size="small" text bg type="primary" plain>
								<el-icon color="brown">
									<SortDown />
								</el-icon>
								{{ item.usage.completion_tokens || 0 }}
							</el-button>
							<el-button v-if="item.usage" size="small" text bg type="primary" plain>
								<el-icon color="red">
									<Sort />
								</el-icon>
								{{ item.usage.total_tokens || 0 }}
							</el-button>
						</div>
					</div>
				</div>


			</div>

		</div>
		<div class="flex">
			<el-input v-model="input_data" maxlength="32000" :disabled="loading" id="user-input"
				placeholder="输入你的问题...(Ctrl + Enter键提交问题)" type="textarea"
				style="width: 100%;flex-grow: 1;margin-right:10px;" :autosize="{ minRows: 1, maxRows: 20 }"
				@keyup.enter="getDeepSeekResponse" />
			<el-button :disabled="loading" type="primary" circle @click="btn_submit">
				<el-icon loading="loading" size="24">
					<Promotion />
				</el-icon>
			</el-button>
		</div>
	</div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick, onUpdated } from 'vue';
import { Refresh, Promotion, SortUp, SortDown, Sort } from '@element-plus/icons-vue'
const ds_settings = ref()
const input_data = ref('');
const last_input = ref('')
const content_dm = ref('');
const content_data = ref('');
const reasoning_data = ref('');
const reasoning_md = ref('');
const content = ref('');
const models = ref([])
const select_model = ref('')
const icon = ref(`<svg class="icon  icon-lg" style="" aria-hidden="true">
			<use class="" href="#icon-deepseek"></use>
		</svg>`)
const loading = ref(false)
const t = (text) => {
	return __(text)
}
const submit_query = ref(false)
const messages = ref([])
const context = ref([])
const current_idx = ref(0)
const avatar = ref('')
const chatContainer = ref(null)

const scrollToBottom = async () => {
	await nextTick()
	if (chatContainer.value) {
		chatContainer.value.scrollTop = chatContainer.value.scrollHeight
	}
}
const sendDeepSeekRequest = async (data) => {
	const headers = {
		Authorization: `Bearer ${ds_settings.value.api_key}`,
		"Content-Type": "application/json",
	};

	const url = `${ds_settings.value.url}/v1/chat/completions`;

	try {
		const response = await fetch(url, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				...data,
				stream: true
			})
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		return response;
	} catch (error) {
		console.error('DeepSeek API Error:', error);
		throw error;
	}
}

const btn_submit = () => {
	submit_query.value = true
	getDeepSeekResponse()
}

onUpdated(() => {
	scrollToBottom()
})


const clear_messages = () => {
	context.value = []
	content_dm.value = []
	content.value = `<div style="text-align: center;">欢迎 ${frappe.user.full_name()}!</div>`
	reasoning_data.value = ''
}

const getDeepSeekResponse = async () => {
	if (!submit_query.value) {
		return
	}
	if (!input_data.value.trim()) return;
	content_data.value = ''
	reasoning_data.value = ''
	loading.value = true

	messages.value.push({
		role: "user",
		content: input_data.value,
		name: frappe.session.user
	})

	context.value.push({
		question: input_data.value,
		reasoning_md: '',
		content_dm: '',
		begin:frappe.datetime.get_datetime_as_string(),
		start_time: performance.now(),
		end_time: performance.now()
	})

	setInterval(() => {
		if (loading.value) {
			context.value[current_idx.value].end_time = performance.now()
		}
	}, 1000)
	try {
		last_input.value = input_data.value
		input_data.value = ''
		send_message()
	} catch (error) {
		console.error('请求失败:', error);
		current_idx.value += 1;
	} finally {
		// btn.disabled = false;
	}
}

const send_message = async () => {
	const response = await sendDeepSeekRequest(
		{
			model: select_model.value,
			messages: messages.value,
			temperature: 0.7,
			max_tokens: 1000
		}
	);
	const reader = response.body.getReader();
	const decoder = new TextDecoder();
	while (true) {
		const { done, value } = await reader.read();
		if (done) break;
		const chunk = decoder.decode(value, { stream: true });
		const lines = chunk.split('\n');
		for (const line of lines) {
			if (line.startsWith('data: ') && line !== 'data: [DONE]') {
				try {
					const data = JSON.parse(line.slice(6));
					if (data.choices && data.choices[0].delta.content) {
						content_data.value += data.choices[0].delta.content;
						content_dm.value = frappe.markdown(content_data.value)
						context.value[current_idx.value].content_dm = frappe.markdown(content_data.value)
					}
					else if (data.choices && data.choices[0].delta.reasoning_content) {
						reasoning_data.value += data.choices[0].delta.reasoning_content;
						reasoning_md.value = frappe.markdown(reasoning_data.value)
						context.value[current_idx.value].reasoning_md = frappe.markdown(reasoning_data.value)
					}
					if (data.usage) {
						context.value[current_idx.value].usage = data.usage;
					}
				} catch (e) {
					console.error('Error parsing JSON:', e);
				}
			} else if (line.startsWith('data: ') && line.includes('[DONE]')) {

				submit_query.value = false;
				context.value[current_idx.value].end_time = performance.now()
				loading.value = false

				messages.value.push({
					role: "assistant",
					content: content_data.value,
					name: 'server'
				})
				
				frappe.call({
					method: "localization.deep_seek.api.create_message_log",
					args: {
						query: last_input.value,
						content: content_data.value,
						reasoning_content: reasoning_data.value,
						model: select_model.value,
						completion_tokens: context.value[current_idx.value].usage.completion_tokens,
						prompt_tokens: context.value[current_idx.value].usage.prompt_tokens,
						total_tokens: context.value[current_idx.value].usage.total_tokens,
						request_time: context.value[current_idx.value].start_time,
						response_time: context.value[current_idx.value].end_time,
						begin: context.value[current_idx.value].begin,
					},
					callback: function (r) {
						console.log(r)
					}
				})
				current_idx.value += 1;
			}
		}
	}
	await reader.cancel();
}

const get_duration = (index) => {
	let end_time = context.value[index]?.end_time || performance.now();
	let start_time = context.value[index]?.start_time || performance.now();
	let count = (end_time - start_time) / 1000
	return count.toFixed(0) + 's'
}
const ds_init = () => {
	ds_settings.value = window.ds_settings;
	window.ds_settings.model.forEach(model => {
		models.value.push({
			value: model.model,
			label: model.model,
		},)
	})
	select_model.value = models.value[0].value;
}

onMounted(() => {
	if (!window.ds_settings) {
		frappe.call({
			method: "localization.deep_seek.api.get_ds_settings",
			args: {},
			callback: (r) => {
				if (r.message) {
					window.ds_settings = r.message;
					ds_init()
				}
			}
		})
	}
	else {
		ds_init()
	}
	setTimeout(() => {
		if (!window.ds_settings) {
			frappe.call({
				method: "localization.deep_seek.api.get_ds_settings",
				args: {},
				callback: (r) => {
					if (r.message) {
						window.ds_settings = r.message;
						ds_init()
					} else {
						frappe.throw('DeepSeek 未配置模型信息!')
					}
				}
			})
		}
	}, 500);

	document.addEventListener('keydown', function (event) {

		if (event.key === 'Enter') {
			if (event.ctrlKey) {
				submit_query.value = true;
			} else {
				submit_query.value = false;
			}
		}
	});
	content.value = `<div style="text-align: center;">欢迎 ${t(frappe.user.full_name())}!</div>`

	avatar.value = frappe.avatar(frappe.session.user, "avatar-medium")
	scrollToBottom()
});
</script>
<style scoped></style>