<template>
	<div v-for="(docs, doctype) in data.undone_list_summary" :key="doctype">
		<div style="margin-top:1rem;font-weight:bold;">
			<div class="flex align-center">
				<div>
					{{ t(doctype) }}
				</div>
				<div class="mx-2">
					<el-button :icon="Refresh" circle size="small" @click="get_data" />
				</div>
			</div>
		</div>
		<div class="doctype workflow_section">
			<div v-for="(item, innerIndex) in docs" :key="innerIndex">
				<el-card shadow="hover" class="mx-2 my-2" style="min-width: 300px;" :style="workflow_style[doctype]">
					<template #header class="workflow_doctype">
						<div class="head_footer">
							<div>
								<a :href="item.url" target="_blank">{{ item.name }}</a>
							</div>
							<div>
								<span class="indicator-pill whitespace-nowrap"
									:class="btn_class_map[item.workflow_state]">
									<span>{{ t(item.workflow_state) }}</span>
								</span>

								<span v-if="doctype == 'Expense Claim' && item.invoice_status"
									class="indicator-pill whitespace-nowrap"
									:class="invoice_status_color_map[item.invoice_status]">
									<span>{{ t(item.invoice_status) }}</span>
								</span>
							</div>
						</div>
						<div>
							<div class="status">{{ item.status }}</div>
						</div>
					</template>
					<div @click="show_details(item, innerIndex, $event)">
						<div>
							<div class="text-center my-1 text-secondary">{{ item.party }}</div>
						</div>
						<div class="workflow_amount">
							{{ item.grand_total.toLocaleString('zh-CN', { style: 'currency', currency: 'CNY' }) }}
						</div>
					</div>

					<template #footer>
						<div class="head_footer">
							<div>
								<span class="text-secondary">{{ item.project.slice(0, 12) }}</span>
							</div>
							<div>
								<span class="text-secondary">最后更新：</span><span style="color:lightcoral">{{ item.modified
									}}</span>
							</div>
						</div>
					</template>
				</el-card>
			</div>
		</div>



	</div>


	<div v-for="(doctype, index) in request_doctypes" :key="index">
		<div v-if="request_docs[doctype]">
			<div style="margin-top:1rem;font-weight:bold;">
				{{ t(doctype) }}
			</div>
			<div class="doctype workflow_section">
				<div v-for="(doc, doc_index) in request_docs[doctype]" :key="doc_index">
					<el-card v-if="!handled_doc.includes(doc.name)" shadow="hover" class="mx-2 my-2"
						style="min-width: 300px;" :style="workflow_style[doctype]">
						<!-- <pre>{{ doc }} {{doctype}}</pre> -->
						<template #header class="workflow_doctype">
							<div class="head_footer">
								<div>
									<a :href="get_doc_link(doctype, doc.name)" target="_blank">{{ doc.name }}</a>
								</div>
								<div>
									<span class="indicator-pill whitespace-nowrap orange">
										<span>{{ t(doc.status) || t('Draft') }}</span>
									</span>
								</div>
							</div>
						</template>

						<div class="">
							<div v-for="(value, key) in doc" :key="key">
								<p v-if="!['name'].includes(key)" class="flex">
								<div class="text-secondary w-25">{{ t(request_labels[doctype][key]) }}:</div>
								<div>{{ t(value) }}</div>
								</p>
							</div>
						</div>

						<template #footer>
							<div class="head_footer">
								<el-button type="danger" size="small">{{ t('Delete') }}</el-button>
								<el-button type="primary" size="small" @click="submit_doc(doctype, doc, $event)">{{
									t('Submit') }}</el-button>
							</div>
						</template>
					</el-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import {
	t,
} from '../base/vue_utils.js'
import { Delete, Edit, Search, Share, Upload, Refresh } from '@element-plus/icons-vue'
const isVisible = ref(false)
const data = reactive({})
const workflow_style = {
	'Sales Order': "background-color: rgb(239.8, 248.9, 235.3)",
	'Purchase Order': "background-color: rgb(252.5, 245.7, 235.5)",
	'Expense Claim': "background-color: rgb(235.9, 245.3, 255)",
	'Payment Application': "background-color: rgb(254, 240.3, 240.3)",
	'Material Request': "background-color: #F2F6FC",
	'Payment Request': "background-color: #FAFCFF",
	'Employee Advance': "background-color: #FAFAFA",
	'Checkin Patch': "background-color: rgb(239.8, 248.9, 235.3)",
	'Travel Request': "background-color: rgb(252.5, 245.7, 235.5)",
	'Attendance Request': "background-color: rgb(235.9, 245.3, 255)",
	'Leave Application': "background-color: rgb(254, 240.3, 240.3)",
}
const show_details = (item, innerIndex, $event) => {
	show_uwl_dialog(item)
}

const btn_class_map = {
	'创建': 'pink',
	'提报': 'orange',
	'部长已审批': 'cyan',
	'总经理已审批': 'blue',
	'取消': 'yellow',
	'拒绝': 'red',
	'审批通过': 'green'
}

const invoice_status_color_map = {
	'待收票': 'orange',
	'已提交': 'green',
	'已退回': 'red',
	'无票': 'yellow',
	'自动识别': 'green',
	'电子发票': 'green',
	'全电发票': 'green',
}


const request_doctypes = [
	'Checkin Patch',
	'Travel Request',
	'Attendance Request',
	'Leave Application',
]

const request_docs = reactive({})
const request_labels = reactive({})
const inlist_fields = [
	'remark', 'status',
	'remarks', 'project',
	'company', 'party',
	'posting_date', 'transaction_date',
	'docstatus', 'name',
	'creation', 'modified', 'description', 'employee_name'
]
const get_doc_link = (doctype, docname) => {
	return `/app/${frappe.router.slug(doctype)}/${encodeURIComponent(docname)}`
}
// docs without workflow
const get_request_docs = (doctype_list) => {
	if (!doctype_list) {
		doctype_list = request_doctypes
	}
	doctype_list.forEach(doctype => {
		frappe.model.with_doctype(doctype, function () {
			let meta = frappe.get_meta(doctype);
			let fields = ['name']
			let labels = {}
			meta.fields.forEach((value) => {
				if (!["Section Break", "Column Break"].includes(value.fieldtype) && (value.in_list_view == 1 || in_list(inlist_fields, value.fieldname))) {
					fields.push(value.fieldname);
					labels[value.fieldname] = value.label;
				}
			});
			request_labels[doctype] = labels
			frappe.db.get_list(doctype,
				{
					filters: {
						docstatus: 0,
					},
					fields: fields
				}
			).then((r) => {
				if (r.length > 0) {
					request_docs[doctype] = r
				}
			})
		})
	})
}
const handled_doc = reactive([])
const submit_doc = (doctype, doc, $event) => {
	ElMessageBox.confirm(
		t("Confirm") + t('Submit') + doc.name + '?',
		t('Warning'),
		{
			confirmButtonText: t('Submit'),
			cancelButtonText: t('Cancel'),
			type: 'warning',
			draggable: true,
			overflow: true,
		}
	)
		.then(() => {
			frappe.db.get_doc(doctype, doc.name).then((doc) => {
				frappe.call({
					method: "frappe.client.submit",
					args: {
						doc: doc,
					},
					callback: function (r) {
						handled_doc.push(doc.name)
						ElMessage({
							type: 'success',
							message: t('Actions') + t('Completed'),
						})
					},
				});
			})


		})
		.catch(() => {
			ElMessage({
				type: 'info',
				message: t('Actions') + t('Is Cancelled'),
			})
		})
}
const get_data = () => {
	frappe.call({
		method: "localization.localization.utils.uwl.get_workflow_data",
		args: { user: frappe.session.user },
		callback: (r) => {
			if (frappe.boot.developer_mode || frappe.user.has_role('System Manager')) {
				console.log(r.message)
			}

			if (r.message) {
				isVisible.value = true
				let res = r.message
				data.undone_list_summary = res.undone_list_summary
				data.workflows = res.workflows
			} else {
				el.innerHTML = `<div>当前无待审批单据</div>`
				el.style.display = 'block'
			}
		}
	})
}
const show_uwl_dialog = (data) => {
	let fields = get_fields(data)
	var d = new frappe.ui.Dialog({
		title: __("Workflow"),
		size: "extra-large",
		fields: fields,
		primary_action: function () {
			d.hide()
		},
		primary_action_label: __('Hide'),
	})
	d.show()
	let element = d.$wrapper.find('#actions')[0];
	const btn_class_map = {
		'Approve': 'btn-primary',
		'Reject': 'btn-danger',
		'Cancelled': 'btn-danger',
		'Check Done': 'btn-primary',
		'Return': 'btn-warning',
		'提交财务': 'btn-primary',
		'财务复核': 'btn-primary',
		'Submit': 'btn-primary',
	}
	if (data.transactions) {
		data.transactions.forEach((item, index) => {
			let tempDiv = document.createElement('div');
			const buttonId = `action-btn-${index}`;
			tempDiv.innerHTML = `
			<div 
					class="btn btn-sm primary-action custom_btn ${btn_class_map[item.action]}" 
					style="min-width: 12rem; margin:10px;"
					id="${buttonId}"
				>
					${__(item.action)} => <span style="font-size:small">${item.next_state}</span>
				</div>
			`;
			element.appendChild(tempDiv);
		})

		// 为每个按钮添加事件监听器
		data.transactions.forEach((item, index) => {
			const button = element.querySelector(`#action-btn-${index}`);
			button.addEventListener('click', function () {
				apply_action(data, item.action, d);
			});
		});
	}
}
const get_fields = (data, element) => {
	let doc = data.doc
	let child_fields = []
	let group_fields = []

	let fields = [
		{
			fieldtype: 'Data',
			fieldname: 'Company',
			label: __('Company'),
			default: doc.company,
			read_only: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'party',
			label: __('Party'),
			default: doc.customer || doc.supplier || doc.party || doc.employee_name || '',
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Date',
			fieldname: 'posting_date',
			label: __('Posting Date'),
			default: doc.posting_date || doc.transaction_date,
			read_only: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'project',
			label: __('Project'),
			default: doc.project || '',
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Currency',
			fieldname: 'grand_total',
			label: __('Grand Total'),
			default: doc.grand_total || doc.amount || doc.payment_amount || 0,
			read_only: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'expense_approver',
			label: __('Expense Approver'),
			default: doc.expense_approver || '',
			read_only: 1,
			depends_on: "eval:doc.expense_approver"
		},
		{
			fieldtype: 'Data',
			fieldname: 'invoice_status',
			label: __('Invoice Status'),
			default: doc.invoice_status || '',
			read_only: 1,
			depends_on: "eval:doc.expense_approver"
		},
		{
			fieldtype: 'Section Break'
		},
		{
			fieldtype: 'Datetime',
			fieldname: 'creation',
			label: __('Created On'),
			default: doc.creation,
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Data',
			fieldname: 'owner',
			label: __('Owner'),
			default: doc.owner,
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Datetime',
			fieldname: 'modified',
			label: __('Modified'),
			default: doc.modified,
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Data',
			fieldname: 'modified_by',
			label: __('Modified By'),
			default: doc.modified_by,
			read_only: 1,
		},
		{
			fieldtype: 'Section Break'
		},
	]
	if (in_list(['Sales Order', 'Purchase Order', 'Material Request'], doc.doctype)) {
		child_fields = [
			{
				fieldtype: 'Link',
				fieldname: 'item_code',
				label: __('Name'),
				in_list_view: 1,
				read_only: 1,
				reqd: 1,
				columns: 1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'item_name',
				label: __('Item Name'),
				in_list_view: 1,
				reqd: 1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'brand',
				label: __('Brand'),
				in_list_view: 1,
				reqd: 1,
				columns: 1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'model_type',
				label: __('Model Type'),
				in_list_view: 1,
				reqd: 1,
				columns: 1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'uom',
				label: __('UOM'),
				in_list_view: 1,
				reqd: 1,
				columns: 1,
			},
			{
				fieldtype: 'Float',
				fieldname: 'qty',
				label: __('Qty'),
				in_list_view: 1,
				reqd: 1,
				columns: 1,
			},
			{
				fieldtype: 'Currency',
				fieldname: 'rate',
				label: __('Rate'),
				in_list_view: 1,
				columns: 1,
			},
			{
				fieldtype: 'Currency',
				fieldname: 'amount',
				label: __('Amount'),
				in_list_view: 1,
				columns: 1,
			},
		]



		group_fields = [
			{
				fieldtype: 'Table',
				fields: child_fields,
				fieldname: 'items',
				label: __('Items'),
				data: doc.items,
				cannot_delete_rows: 1,
				cannot_add_rows: 1,
			}
		]

		if (doc.taxes) {
			group_fields.push(
				{
					fieldtype: 'Section Break'
				},
			)
			group_fields.push(
				{
					fieldtype: 'Data',
					fieldname: 'taxes_and_charges',
					label: __('Taxes and Charges'),
					in_list_view: 1,
					reqd: 1,
					default: doc.taxes_and_charges || '',
					read_only: 1,
				},
			)
			group_fields.push(
				{
					fieldtype: 'Table',
					fields: [
						{
							fieldtype: 'Data',
							fieldname: 'charge_type',
							label: __('Charge Type'),
							in_list_view: 1,
							reqd: 1,
						},
						{
							fieldtype: 'Data',
							fieldname: 'account_head',
							label: __('Account Head'),
							in_list_view: 1,
							reqd: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'rate',
							label: __('Tax Rate'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'tax_amount',
							label: __('Tax Amount'),
							in_list_view: 1,
						},
					],
					fieldname: 'taxes',
					label: __('Taxes'),
					data: doc.taxes,
					cannot_delete_rows: 1,
					cannot_add_rows: 1,
				}
			)
		}
	} else if (in_list(['Expense Claim'], doc.doctype)) {
		child_fields = [
			{
				fieldtype: 'Link',
				fieldname: 'expense_type',
				label: __('Expense Type'),
				in_list_view: 1,
				read_only: 1,
				reqd: 1,
				columns: 1,
			},
			{
				fieldtype: 'Text Editor',
				fieldname: 'description',
				label: __('Description'),
				in_list_view: 1,
				reqd: 1,
			},
			{
				fieldtype: 'Currency',
				fieldname: 'amount',
				label: __('Amount'),
				in_list_view: 1,
				columns: 1,
				read_only: 1,
			},
			{
				fieldtype: 'Link',
				fieldname: 'invoice_recognition',
				label: __('Invoice Recognition'),
				options: 'Invoice Recognition',
				in_list_view: 1,
				columns: 1,
				read_only: 1,
			},
			{
				fieldtype: 'Link',
				fieldname: 'e_invoice',
				label: __('E Invoice'),
				options: 'E Invoice',
				in_list_view: 1,
				columns: 1,
				read_only: 1,
			},
			{
				fieldtype: 'Link',
				fieldname: 'invoice_recognition_group',
				label: __('Invoice Recognition Group'),
				options: 'Invoice Recognition Group',
				in_list_view: 1,
				columns: 1,
				read_only: 1,
			},
		]
		if (in_list(doc.project, '市场开拓')) {
			child_fields.push(
				{
					fieldtype: 'Data',
					fieldname: 'opportunity_description',
					label: __('Opportunity Description'),
					in_list_view: 1,
					reqd: 1,
				}
			)
		}

		group_fields = [
			{
				fieldtype: 'Table',
				fields: child_fields,
				fieldname: 'expenses',
				label: __('Expenses'),
				data: doc.expenses,
				cannot_add_rows: 1,
				cannot_delete_rows: 1,
				readonly: 1,
			}
		]

		if (doc.taxes?.length > 0) {
			group_fields.push(
				{
					fieldtype: 'Table',
					fields: [
						{
							fieldtype: 'Data',
							fieldname: 'account_head',
							label: __('Account Head'),
							in_list_view: 1,
							reqd: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'rate',
							label: __('Tax Rate'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'tax_amount',
							label: __('Tax Amount'),
							in_list_view: 1,
						},
					],
					fieldname: 'taxes',
					label: __('Taxes'),
					data: doc.taxes,
					cannot_delete_rows: 1,
					cannot_add_rows: 1,
				}
			)
		}

		let ir = 0
		data.doc.expenses.forEach(expense => {
			if (expense.invoice_recognition || expense.e_invoice) {
				ir += 1
			}
		})
		if (ir > 0) {
			group_fields.push(
				{
					fieldtype: 'Button',
					fieldname: 'preview',
					label: __('Invoice') + __('Preview') + `(${ir})`,
					in_list_view: 1,
					click: function (e) {
						preview_all_invoice(data.doc)
					}
				},
			)
		} else {
			msg = `
				<p style="margin:20px">未检测到发票关联</p>
				<p style="margin:20px;">当前发票状态为：<span style="font-weight:bold;font-size:x-large">${data.doc.invoice_status}</span></p>
				<p style="margin:20px">请确认是否为无票报销</p>
			`
			frappe.msgprint(msg)
		}

		if (doc.advances?.length > 0) {
			group_fields.push(
				{
					fieldtype: 'Table',
					fields: [
						{
							fieldtype: 'Link',
							fieldname: 'employee_advance',
							label: __('Employee Advance'),
							options: 'Employee Advance',
							in_list_view: 1,
						},
						{
							fieldtype: 'Date',
							fieldname: 'posting_date',
							label: __('Posting Date'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'advance_paid',
							label: __('Advance Paid'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'unclaimed_amount',
							label: __('Unclaimed Amount'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'allocated_amount',
							label: __('Allocated Amount'),
							in_list_view: 1,
						},
					],
					fieldname: 'advances',
					label: __('Advances'),
					data: doc.advances,
					cannot_delete_rows: 1,
					cannot_add_rows: 1,
				}
			)
		}


	} else if (in_list(['Payment Application'], doc.doctype)) {
		group_fields = [
			{
				fieldtype: 'Data',
				fieldname: 'transaction_type',
				label: __('Transaction Type'),
				read_only: 1,
				default: doc.transaction_type
			},
			{
				fieldtype: 'Column Break'
			},
			{
				fieldtype: 'Data',
				fieldname: 'journal_entry_template',
				label: __('Journal Entry Template'),
				read_only: 1,
				default: doc.journal_entry_template
			},
			{
				fieldtype: 'Column Break'
			},
			{
				fieldtype: 'Data',
				fieldname: 'party',
				label: __('Party'),
				read_only: 1,
				default: doc.party
			},
		]
	} else if (in_list(['Payment Request'], doc.doctype)) {
		group_fields = [
			{
				fieldtype: 'Link',
				fieldname: 'reference_doctype',
				options: 'DocType',
				label: __('Reference Doctype'),
				read_only: 1,
				default: __(doc.reference_doctype)
			},
			{
				fieldtype: 'Dynamic Link',
				fieldname: 'reference_name',
				options: 'reference_doctype',
				label: __('Reference Name'),
				read_only: 1,
				default: doc.reference_name
			},
			{
				fieldtype: 'Column Break'
			},
			{
				fieldtype: 'Data',
				fieldname: 'pay_to_employee',
				label: __('Pay To Employee'),
				read_only: 1,
				default: doc.pay_to_employee,
				depends_on: "eval:doc.pay_to_employee",
				hidden: 1
			},
			{
				fieldtype: 'Data',
				fieldname: 'payee_name',
				label: __('Payee Name'),
				read_only: 1,
				default: doc.payee_name,
				depends_on: "eval:doc.pay_to_employee",
				description: "付款给个人"
			},

		]
	}
	fields.push(...group_fields)


	let workflow_comment_fields = [
		{
			fieldtype: 'Datetime',
			fieldname: 'modified',
			label: __('Modified'),
			read_only: 1,
			in_list_view: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'content',
			label: __('Content'),
			read_only: 1,
			in_list_view: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'first_name',
			label: __('First Name'),
			read_only: 1,
			in_list_view: 1,
		},
	]

	fields.push(
		{
			fieldtype: 'Section Break'
		},
	)
	fields.push(
		{
			fieldtype: 'Table',
			fields: workflow_comment_fields,
			fieldname: 'workflow_comment',
			label: __('Workflow') + __('Activity'),
			data: data.workflow_comment,
			cannot_add_rows: 1,
			cannot_delete_rows: 1,
		}
	)

	if (doc.remarks || doc.remark) {
		fields.push(
			{
				fieldtype: 'Section Break'
			},
		)
		fields.push(
			{
				fieldtype: 'Data',
				fieldname: 'remarks',
				label: __('Remarks'),
				default: doc.remarks || doc.remark || '',
				read_only: 1,
			},
		)
	}

	let actions_feilds = [
		{
			fieldtype: 'Section Break',
			label: __('Workflow Action'),
		},
		{
			fieldtype: 'HTML',
			fieldname: 'actions',
			options: `
				<div id = 'actions' class="flex flex-wrap align-items-center justify-content-center"  data-doctype="${doc.doctype}" data-name="${doc.name}"></div>
			`
		},
	]
	fields.push(...actions_feilds)

	return fields
}

const apply_action = (data, action, d) => {
	frappe.call({
		method: "frappe.model.workflow.apply_workflow",
		args: {
			doc: data.doc,
			action: action
		},
		callback: (res) => {
			if (res.message) {
				frappe.show_alert({ message: __("Successfully Done"), indicator: "green" });
			}
			d.hide()
			reload_doc(data.doc)
		}
	})
}


const reload_doc = (doc) => {

	frappe.call({
		method: "localization.localization.utils.uwl.get_workflow_data",
		args: {
			user: frappe.session.user,
			doc_for_update:doc
		},
		callback: (r) => {
			if (frappe.boot.developer_mode || frappe.user.has_role('System Manager')) {
				console.log(r.message)
			}
			if(Object.keys(r.message).length === 0){
				data.undone_list_summary[doc.doctype] = data.undone_list_summary[doc.doctype].filter(row=>row.name != doc.name)
			} else {
				let new_doc = r.message
				data.undone_list_summary[doc.doctype].forEach(doc_info=>{
					if(doc_info.name == new_doc.name){
						doc_info.workflow_state = new_doc.workflow_state
						doc_info.transactions = new_doc.transactions
					}
				})
			}
		}
	})
}

onMounted(() => {
	get_data()
	get_request_docs()
});



</script>

<style scoped></style>